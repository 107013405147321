<template>
  <v-container fluid>
    <v-card raised>
      <v-card-title class="bg-clr">
        <v-btn fab flat @click.native="goBack">
          <v-icon class="pointer" v-ripple>arrow_back</v-icon>
        </v-btn>
        <div class="px-2 headline">New Job</div>
      </v-card-title>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        onSubmit="return false;"
      >
        <v-card-text class="content-border">
          <div class="heading primary-clr">Customer Details</div>
          <v-layout row wrap>
            <v-flex class="pr-3" xs12 md4>
              <span class="muted caption">Company Name*</span>
              <v-text-field
                v-if="customerId"
                v-model="ownerName"
                readonly
                class="pt-0 currencyTitle"
              ></v-text-field>
              <v-autocomplete
                class="pt-0 currencyTitle"
                v-if="!customerId"
                :items="customers"
                v-model="x.customerId"
                label="Select Company Name*"
                required
                single-line
                autocomplete="offfadsf"
                :rules="[rules.required]"
                @change="setCustomerValues($event)"
              />
            </v-flex>

            <!-- <v-flex class="pr-3"  xs12 md4>
            <span class="muted caption">Country Code*</span>
                <v-text-field
                  label=" Enter Country Code"
                  v-model="x.countryCode"
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.countryCode,rules.required]"
                  required
              />
            </v-flex>-->
            <v-flex class="pr-3" xs12 md4>
              <span class="muted caption">Billing Address*</span>
              <v-text-field
                label="Enter Billing Address"
                class="pt-0 currencyTitle"
                v-model="x.customerAddress"
                :value="x.customerAddress"
                single-line
                :rules="[rules.noWhiteSpace, rules.required]"
                maxlength="100"
              />
            </v-flex>
            <v-flex class="pr-3" xs12 md4 v-if="x.customerCurrency != 'PKR'">
              <span class="muted caption">{{ "VAT Number*" }}</span>
              <v-text-field
                label="Enter Number*"
                class="pt-0 currencyTitle"
                v-model="x.vatNumber"
                :value="x.vatNumber"
                single-line
                :rules="[rules.noWhiteSpace, rules.required]"
                counter
                maxlength="20"
              />
            </v-flex>
            <v-flex class="pr-3" xs12 md4 v-else>
              <span class="muted caption">{{ "STRN Number" }}</span>
              <v-text-field
                label="Enter Number"
                class="pt-0 currencyTitle"
                v-model="x.vatNumber"
                :value="x.vatNumber"
                single-line
                counter
                maxlength="20"
              />
            </v-flex>

            <v-flex class="pr-3" xs12 md4>
              <!-- <customer-currency
              label="Select Currency"
              required
              :onselect="currency =>(x.customerCurrency = currency)"
            /> -->
              <span class="muted caption">Currency*</span>
              <v-text-field
                readonly
                label="Select Currency"
                class="pt-0 currencyTitle"
                v-model="x.customerCurrency"
                :value="x.customerCurrency"
                single-line
              />
            </v-flex>
            <v-flex class="pr-3" xs12 md4>
              <span class="muted caption">Industry*</span>
              <v-autocomplete
                label="Select Industry"
                :items="cargos"
                :disabled="!x.customerId"
                class="pt-0 currencyTitle"
                item-text="name"
                item-value="numericIdentifier"
                v-model="x.selectedIndustry"
                single-line
                autocomplete="offfadsf"
                :rules="[rules.required]"
              />
            </v-flex>

            <v-flex class="pr-3" style="padding-bottom: 1px" xs12 md4>
              <span class="muted caption">Packing Type*</span>
              <v-autocomplete
                label="Select Packing Type"
                :items="packings"
                class="pt-0 currencyTitle"
                v-model="x.packingType"
                autocomplete="offfadsf"
                single-line
                :rules="[rules.required]"
              />
            </v-flex>

            <v-flex class="pr-3" xs12 md4>
              <span class="muted caption">Commodity</span>
              <v-text-field
                label="Enter Commodity"
                class="pt-0 currencyTitle"
                v-model="x.commodity"
                single-line
                :value="x.commodity"
              />
            </v-flex>

            <v-flex class="pr-3" xs12 md4 v-if="x.invoiceBy && x.customerId">
              <v-radio-group
                class="mt-0 pt-0"
                v-model="x.invoiceBy"
                row
                :mandatory="true"
                disabled
              >
                <div>
                  <div class="muted caption">Selected Invoice Country</div>
                  <div class="">
                    <v-layout>
                      <v-radio
                        :label="
                          x.invoiceBy == 1
                            ? 'KSA'
                            : '' || x.invoiceBy == 2
                            ? 'UAE'
                            : '' || x.invoiceBy == 3
                            ? 'PAK'
                            : '' || x.invoiceBy == 4
                            ? 'UAE LA'
                            : '' || x.invoiceBy == 5
                            ? 'OMAN'
                            : ''
                        "
                        :value="x.invoiceBy"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                    </v-layout>
                  </div>
                </div>
              </v-radio-group>
              <span
                v-if="x.invoiceByError"
                style="
                  color: red;
                  width: 50%;
                  border: 0px;
                  margin: auto;
                  text-align: center;
                  font-size: 12px;
                "
                >Select InvoiceBy</span
              >
            </v-flex>
            <v-flex
              class="pr-3"
              xs12
              md4
              v-if="x.invoiceBy == 2 && x.customerId"
            >
              <v-radio-group
                class="mt-0 pt-0"
                v-model="x.invoicingCity"
                disabled
                row
                :mandatory="true"
              >
                <div>
                  <div class="muted caption">Selected Invoice City</div>
                  <div class="">
                    <v-layout>
                      <v-radio
                        label="FZ"
                        value="1"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="LLC"
                        value="2"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                    </v-layout>
                  </div>
                </div>
              </v-radio-group>
            </v-flex>
            <v-flex
              class="pr-3"
              xs12
              md4
              v-if="x.invoiceBy == 1 && x.customerId"
            >
              <v-radio-group
                class="mt-0 pt-0"
                v-model="x.invoicingCity"
                disabled
                row
                :mandatory="true"
              >
                <div>
                  <div class="muted caption">Select Invoice City</div>
                  <div class="">
                    <v-layout>
                      <v-radio
                        label="Damman"
                        value="1"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="Jeddah"
                        value="2"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="Riyadh"
                        value="3"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                    </v-layout>
                  </div>
                </div>
              </v-radio-group>
            </v-flex>
            <v-flex
              class="pr-3"
              xs12
              md4
              v-if="x.invoiceBy == 3 && x.customerId"
            >
              <v-radio-group
                class="mt-0 pt-0"
                v-model="x.invoicingCity"
                disabled
                row
                :mandatory="true"
              >
                <div>
                  <div class="muted caption">Select Invoice City</div>
                  <div class="">
                    <v-layout>
                      <v-radio
                        label="Lahore"
                        value="1"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="Karachi"
                        value="2"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="Faisalabad"
                        value="3"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="Multan"
                        value="4"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                    </v-layout>
                  </div>
                </div>
              </v-radio-group>
            </v-flex>
            <v-flex
              class="pr-3"
              xs12
              md4
              v-if="x.invoiceBy == 4 && x.customerId"
            >
              <v-radio-group
                class="mt-0 pt-0"
                v-model="x.invoicingCity"
                disabled
                row
                :mandatory="true"
              >
                <div>
                  <div class="muted caption">Selected Invoice City</div>
                  <div class="">
                    <v-layout>
                      <v-radio
                        label="DXB"
                        value="1"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                    </v-layout>
                  </div>
                </div>
              </v-radio-group>
            </v-flex>
            <v-flex
              class="pr-3"
              xs12
              md4
              v-if="x.invoiceBy == 5 && x.customerId"
            >
              <v-radio-group
                class="mt-0 pt-0"
                v-model="x.invoicingCity"
                disabled
                row
                :mandatory="true"
              >
                <div>
                  <div class="muted caption">Selected Invoice City</div>
                  <div class="">
                    <v-layout>
                      <v-radio
                        label="Sohar"
                        value="1"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="Muscat"
                        value="2"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="Salalah"
                        value="3"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                    </v-layout>
                  </div>
                </div>
              </v-radio-group>
            </v-flex>
            <v-flex class="pr-3" xs12 md3>
              <v-radio-group
                class="mt-0 pt-0"
                v-model="x.chargesType"
                row
                :mandatory="true"
                :rules="[rules.required]"
              >
                <div>
                  <div class="subheading muted caption">
                    Prices are exclusive/inclusive*
                  </div>
                  <div class="heading">
                    <v-layout>
                      <v-radio
                        label="Exclusive"
                        value="Exclusive"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="Inclusive"
                        value="Inclusive"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                    </v-layout>
                  </div>
                  <div
                    class="subheading muted caption"
                    v-if="x.chargesType == 'Inclusive'"
                  >
                    *All additional prices will not be included in invoices.
                  </div>
                  <div
                    class="subheading muted caption"
                    v-if="x.chargesType == 'Exclusive'"
                  >
                    *All additional prices will be included in invoices.
                  </div>
                </div>
              </v-radio-group>
            </v-flex>
            <v-flex> </v-flex>
          </v-layout>
          <hr class="divider-rule" />

          <div>
            <v-stepper v-model="e1" id="jobSection">
              <v-stepper-header class="SubTypeTab">
                <template v-for="n in steps" class="bg-clr-tab">
                  <v-stepper-step
                    :complete="e1 > n"
                    :key="`${n}-step`"
                    :step="n"
                    color="orange darken-1"
                    class="bg-clr-tab"
                  >
                    <span v-if="x.sub[n - 1].jobType == null"
                      >SubType {{ n }}
                    </span>
                    <span v-if="x.sub[n - 1].jobType == 1">Land {{ n }}</span>
                    <span v-if="x.sub[n - 1].jobType == 2">Air {{ n }}</span>
                    <span v-if="x.sub[n - 1].jobType == 3">Sea {{ n }}</span>
                  </v-stepper-step>
                </template>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content
                  v-for="n in steps"
                  :key="`${n}-content`"
                  :step="n"
                >
                  <div class="heading primary-clr">Shipment Details</div>
                  <v-layout row wrap>
                    <v-flex class="pr-3" xs12 md2>
                      <v-autocomplete
                        :items="jobTypes"
                        :disabled="jobTypes.disabled"
                        v-model="x.sub[n - 1].jobType"
                        label="Select Job Type*"
                        required
                        single-line
                        :rules="[rules.required]"
                        @change="setShipmentValues($event, n)"
                      />
                    </v-flex>
                    <v-flex class="pr-3" xs12 md4>
                      <v-radio-group
                        class="mt-0 pt-0"
                        v-model="x.sub[n - 1].jobBooking"
                        row
                        :mandatory="true"
                        :rules="[rules.required]"
                      >
                        <div>
                          <div class="subheading muted caption">
                            Select Job Booking*
                          </div>
                          <div class="heading">
                            <v-layout>
                              <v-radio
                                label="Load movement"
                                value="2"
                                color="black"
                                class="col-md-6"
                              ></v-radio>
                              <v-radio
                                label="Documentation"
                                value="1"
                                color="black"
                                class="col-md-6"
                              ></v-radio>
                            </v-layout>
                          </div>
                        </div>
                      </v-radio-group>
                      <span
                        v-if="x.sub[n - 1].jobBookingError"
                        style="
                          color: red;
                          width: 50%;
                          border: 0px;
                          margin: auto;
                          text-align: center;
                          font-size: 12px;
                        "
                        >Select Job Mode</span
                      >
                    </v-flex>

                    <v-flex class="pr-3" xs12 md3>
                      <v-radio-group
                        class="mt-0 pt-0"
                        v-model="x.sub[n - 1].jobGeoType"
                        @change="reSetJobMode(x.sub[n - 1].jobGeoType, n)"
                        row
                        :rules="[rules.required]"
                      >
                        <div>
                          <div class="subheading muted caption">
                            Select Job Geography Type*
                          </div>
                          <div class="heading">
                            <v-layout>
                              <v-radio
                                label="Domestic"
                                value="2"
                                color="black"
                                class="col-md-6"
                              >
                              </v-radio>
                              <v-radio
                                label="International"
                                color="black"
                                value="1"
                                class="col-md-6"
                              ></v-radio>
                            </v-layout>
                          </div>
                        </div>
                      </v-radio-group>
                      <span
                        v-if="x.sub[n - 1].jobGeoTypeError"
                        style="
                          color: red;
                          width: 50%;
                          border: 0px;
                          margin: auto;
                          text-align: center;
                          font-size: 12px;
                        "
                        >Select Job Geography Type</span
                      >
                    </v-flex>

                    <v-flex class="pr-3" xs12 md3>
                      <v-radio-group
                        class="mt-0 pt-0"
                        v-model="x.sub[n - 1].jobMode"
                        row
                        :mandatory="true"
                        :rules="[rules.required]"
                      >
                        <div>
                          <div class="subheading muted caption">
                            Select Job Mode*
                          </div>
                          <div class="heading">
                            <v-layout>
                              <v-radio
                                label="Export"
                                value="2"
                                color="black"
                                class="col-md-6"
                              ></v-radio>
                              <v-radio
                                label="Import"
                                value="1"
                                color="black"
                                class="col-md-6"
                              ></v-radio>
                              <v-radio
                                v-if="
                                  x.sub[n - 1].jobGeoType == 2 &&
                                  x.sub[n - 1].jobType == 1
                                "
                                color="black"
                                label="Local"
                                value="3"
                                class="col-md-6"
                              ></v-radio>
                            </v-layout>
                          </div>
                        </div>
                      </v-radio-group>
                      <span
                        v-if="x.sub[n - 1].jobModeError"
                        style="
                          color: red;
                          width: 50%;
                          border: 0px;
                          margin: auto;
                          text-align: center;
                          font-size: 12px;
                        "
                        >Select Job Mode</span
                      >
                    </v-flex>
                    <v-layout>
                      <v-flex xs9></v-flex>
                      <v-flex
                        class="pr-3"
                        xs3
                        v-if="
                          x.customerCurrency == 'PKR' &&
                          x.sub[n - 1].jobGeoType == '2' &&
                          x.sub[n - 1].jobMode == '3'
                        "
                      >
                        <v-radio-group
                          class="mt-0 pt-0"
                          v-model="x.sub[n - 1].loadType"
                          row
                          :mandatory="true"
                          :rules="[rules.required]"
                        >
                          <div>
                            <div class="subheading muted caption"></div>
                            <div class="heading">
                              <v-layout>
                                <v-radio
                                  label=" LTL"
                                  value="1"
                                  color="black"
                                  class="col-md-6"
                                ></v-radio>
                                <v-radio
                                  label=" FTL"
                                  value="2"
                                  color="black"
                                  class="col-md-6"
                                ></v-radio>
                              </v-layout>
                            </div>
                          </div>
                        </v-radio-group>
                        <span
                          v-if="x.sub[n - 1].loadTypeError"
                          style="
                            color: red;
                            width: 50%;
                            border: 0px;
                            margin: auto;
                            text-align: center;
                            font-size: 12px;
                          "
                          >Select Job Mode</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-layout>

                  <hr class="divider-rule" />
                  <div class="heading primary-clr">Carriage Details</div>
                  <v-layout row wrap>
                    <v-flex class="pr-3" xs12 md4>
                      <v-radio-group
                        class="mt-0 pt-0"
                        v-model="x.sub[n - 1].priceType"
                        @change="checkPriceType(x.sub[n - 1].priceType, n)"
                        row
                        :mandatory="true"
                        :rules="[rules.required]"
                      >
                        <div>
                          <div class="subheading muted caption">
                            Enter price for job/assignment*
                          </div>
                          <div class="heading">
                            <v-layout>
                              <v-radio
                                label="Job"
                                value="job"
                                color="black"
                                class="col-md-6"
                              ></v-radio>
                              <v-radio
                                label="Assignment"
                                value="assignment"
                                color="black"
                                class="col-md-6"
                              ></v-radio>
                            </v-layout>
                          </div>
                        </div>
                      </v-radio-group>
                      <span
                        v-if="x.sub[n - 1].priceTypeError"
                        style="
                          color: red;
                          width: 50%;
                          border: 0px;
                          margin: auto;
                          text-align: center;
                          font-size: 12px;
                        "
                        >Select price type for job/assignmnent</span
                      >
                    </v-flex>
                    <v-flex class="pr-3" xs12 md4>
                      <span class="muted">Customer Rate*</span>

                      <v-text-field
                        label="Customer Rate*"
                        required
                        class="pt-0"
                        v-model="x.sub[n - 1].jobPrice"
                        :value="x.sub[n - 1].jobPrice"
                        @input="setJobPrice(n)"
                        min="0"
                        single-line
                        :rules="[rules.required, rules.decmialAllowNumberFloat]"
                      />
                    </v-flex>

                    <v-flex class="pr-3" xs12 md4>
                      <span class="muted" v-if="x.sub[n - 1].isAssignment"
                        >Job Price</span
                      >
                      <v-text-field
                        v-if="x.sub[n - 1].isAssignment"
                        type="text"
                        label="Job price"
                        class="pt-0"
                        single-line
                        v-model="x.sub[n - 1].assignmentjobPrice"
                        :value="x.sub[n - 1].assignmentjobPrice"
                        :disabled="true"
                      />
                    </v-flex>
                    <v-flex
                      class="pr-3"
                      xs12
                      md4
                      v-if="x.sub[n - 1].jobType == 1"
                    >
                      <span class="muted caption">Category of Truck*</span>
                      <v-autocomplete
                        label="Select Category of Truck"
                        :items="trucks"
                        class="pt-0"
                        v-model="x.sub[n - 1].truckTypeParent"
                        single-line
                        autocomplete="offfadsf"
                        :rules="[rules.requiredLocation]"
                        @change="truckCat(x.sub[n - 1].truckTypeParent, n)"
                      />
                    </v-flex>
                    <v-flex
                      class="pr-3"
                      xs12
                      md4
                      v-if="x.sub[n - 1].jobType == 1"
                    >
                      <span class="muted caption">Sub-category of Truck*</span>
                      <v-autocomplete
                        label="Select Sub-Category of Truck"
                        :items="x.sub[n - 1].trucksSub"
                        class="pt-0"
                        v-model="x.sub[n - 1].truckType"
                        single-line
                        autocomplete="offfadsf"
                        :rules="[rules.required]"
                      />
                    </v-flex>
                    <v-flex
                      class="pr-3"
                      xs12
                      md4
                      v-if="x.sub[n - 1].jobType == 1"
                    >
                      <span class="muted caption"
                        >Number of Trucks*( should not exceed 100)</span
                      >
                      <v-text-field
                        type="number"
                        class="pt-0"
                        required
                        min="1"
                        max="100"
                        v-model="x.sub[n - 1].numberOfAssignmentsRequired"
                        :value="x.sub[n - 1].numberOfAssignmentsRequired"
                        autocomplete="offfadsf"
                        @input="setJobPrice(n)"
                        :rules="[
                          rules.min,
                          rules.max,
                          rules.number,
                          rules.required,
                        ]"
                      />
                    </v-flex>

                    <v-flex
                      class="pr-3"
                      xs12
                      md4
                      v-if="x.sub[n - 1].jobType == 2"
                    >
                      <span class="muted caption">Category of Cargo*</span>
                      <v-autocomplete
                        label="Select Category of Cargo"
                        :items="airTrucks"
                        class="pt-0"
                        v-model="x.sub[n - 1].truckTypeParent"
                        single-line
                        autocomplete="offfadsf"
                        :rules="[rules.required]"
                        @change="airCat(x.sub[n - 1].truckTypeParent, n)"
                      />
                    </v-flex>
                    <v-flex
                      class="pr-3"
                      xs12
                      md4
                      v-if="x.sub[n - 1].jobType == 2"
                    >
                      <span class="muted caption">Sub-category of Cargo*</span>
                      <v-autocomplete
                        label="Select Sub-Category of Cargo"
                        :items="x.sub[n - 1].airTrucksSub"
                        class="pt-0"
                        v-model="x.sub[n - 1].truckType"
                        single-line
                        autocomplete="offfadsf"
                        :rules="[rules.required]"
                      />
                    </v-flex>
                    <v-flex
                      class="pr-3"
                      xs12
                      md4
                      v-if="x.sub[n - 1].jobType == 2"
                    >
                      <span class="muted caption"
                        >Number of Cargo planes*( should not exceed 100)</span
                      >
                      <v-text-field
                        type="number"
                        label=""
                        required
                        min="1"
                        max="100"
                        class="pt-0"
                        v-model="x.sub[n - 1].numberOfAssignmentsRequired"
                        :value="x.sub[n - 1].numberOfAssignmentsRequired"
                        autocomplete="offfadsf"
                        @input="setJobPrice(n)"
                        :rules="[rules.min, rules.max, rules.required]"
                      />
                      <!-- <span class="muted caption"
                        >Number of Cargo should not exceed 100*</span
                      > -->
                    </v-flex>
                    <v-flex
                      class="pr-3"
                      xs12
                      md4
                      v-if="x.sub[n - 1].jobType == 3"
                    >
                      <span class="muted caption">Category of Containers*</span>
                      <v-autocomplete
                        label="Select Category of Containers"
                        :items="seaTrucks"
                        class="pt-0"
                        v-model="x.sub[n - 1].truckTypeParent"
                        single-line
                        autocomplete="offfadsf"
                        :rules="[rules.required]"
                        @change="seaCat(x.sub[n - 1].truckTypeParent, n)"
                      />
                    </v-flex>
                    <v-flex
                      class="pr-3"
                      xs12
                      md4
                      v-if="x.sub[n - 1].jobType == 3"
                    >
                      <span class="muted caption"
                        >Sub-category of Containers*</span
                      >
                      <v-autocomplete
                        label="Select Sub-Category of Containers"
                        :items="x.sub[n - 1].seaTrucksSub"
                        class="pt-0"
                        v-model="x.sub[n - 1].truckType"
                        single-line
                        autocomplete="offfadsf"
                        :rules="[rules.required]"
                      />
                    </v-flex>
                    <v-flex
                      class="pr-3"
                      xs12
                      md4
                      v-if="x.sub[n - 1].jobType == 3"
                    >
                      <span class="muted caption">
                        Number of Containers planes*( should not exceed
                        100)</span
                      >
                      <v-text-field
                        type="number"
                        label=""
                        class="pt-0"
                        required
                        min="1"
                        max="100"
                        v-model="x.sub[n - 1].numberOfAssignmentsRequired"
                        :value="x.sub[n - 1].numberOfAssignmentsRequired"
                        autocomplete="offfadsf"
                        @input="setJobPrice(n)"
                        :rules="[rules.min, rules.max, rules.required]"
                      />
                    </v-flex>
                    <v-flex class="pr-3" xs12 md4 v-if="backDateRole">
                      <span class="muted caption"> Job Start Date*</span>

                      <v-menu
                        ref="jobStartDateBool"
                        lazy
                        v-model="x.sub[n - 1].jobStartDateBool"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        full-width
                        :nudge-right="40"
                        min-width="290px"
                        :return-value.sync="x.sub[n - 1].startDateSelect"
                      >
                        <v-text-field
                          slot="activator"
                          class="pt-0"
                          label="Job Start Date*"
                          required
                          v-model="x.sub[n - 1].startDateSelect"
                          readonly
                          single-line
                          :rules="[rules.required]"
                        >
                          <v-icon slot="prepend" color="poobrown">event</v-icon>
                        </v-text-field>
                        <v-date-picker
                          v-model="x.sub[n - 1].startDateSelect"
                          class="minHeight"
                          no-title
                          scrollable
                          :rules="[rules.required]"
                          @change="
                            $refs.jobStartDateBool[n - 1].save(
                              x.sub[n - 1].startDateSelect
                            )
                          "
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex class="pr-3" xs12 md4 v-else>
                      <span class="muted caption"> Job Start Date*</span>

                      <v-menu
                        ref="jobStartDateBool"
                        lazy
                        v-model="x.sub[n - 1].jobStartDateBool"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        full-width
                        :nudge-right="40"
                        min-width="290px"
                        :return-value.sync="x.sub[n - 1].startDateSelect"
                      >
                        <v-text-field
                          slot="activator"
                          label="Job Start Date*"
                          class="pt-0"
                          required
                          v-model="x.sub[n - 1].startDateSelect"
                          readonly
                          :min="dateToday"
                          single-line
                          :rules="[rules.required]"
                        >
                          <v-icon slot="prepend" color="poobrown">event</v-icon>
                        </v-text-field>
                        <v-date-picker
                          v-model="x.sub[n - 1].startDateSelect"
                          class="minHeight"
                          no-title
                          :min="dateToday"
                          scrollable
                          :rules="[rules.required]"
                          @change="
                            $refs.jobStartDateBool[n - 1].save(
                              x.sub[n - 1].startDateSelect
                            )
                          "
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex
                      class="pr-3 pl-5 pt-19"
                      xs12
                      md3
                      style="position: relative; padding-top: 5px"
                    >
                      <span class="muted caption">Pickup Time*</span>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="test1"
                        height="45"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path
                          d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                        />
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
                      </svg>
                      <div>
                        <vue-timepicker
                          class="fonttime"
                          :minute-interval="30"
                          v-model="x.sub[n - 1].pickupTime"
                          input-width="22em"
                          placeholder="Pickup Time*"
                          close-on-complete
                          hide-clear-button
                          @change="jobPickupTimePicker($event, n)"
                        ></vue-timepicker>
                      </div>
                      <v-flex>
                        <span
                          v-if="x.sub[n - 1].pickupTimeError"
                          style="
                            color: red;
                            width: 50%;
                            border: 0px;
                            margin: auto;
                            text-align: center;
                            font-size: 11px;
                          "
                          >This field is required.
                        </span>
                      </v-flex>
                    </v-flex>
                    <!-- <v-flex
                      class="pl-5 "
                      xs12
                      md3
                      style="padding-left:105px !important"
                    >
                      <span class="muted caption pt-8">Weight(in tons)</span>

                      <v-text-field
                        type="number"
                        class="pt-0"
                        label="Weight(in tons)"
                        v-model="x.weightInTons"
                        :value="x.weightInTons"
                        autocomplete="offfadsf"
                        single-line
                      />
                    </v-flex> -->
                  </v-layout>
                  <hr class="divider-rule" />

                  <div class="heading primary-clr">Loading Station (From)</div>
                  <v-layout row wrap>
                    <v-flex class="pr-3" xs12 md6>
                      <span class="muted caption">Source Address*</span>

                      <v-text-field
                        label="Address*"
                        class="pt-0"
                        :id="`autocomplete1-${n - 1}`"
                        placeholder="Search source address*"
                        v-model="x.sub[n - 1].sourceLocation"
                        :value="x.sub[n - 1].sourceLocation"
                        autocomplete="offfadsf"
                        :rules="[rules.required]"
                        single-line
                      />
                    </v-flex>
                    <v-flex class="pr-3" xs12 md3>
                      <span class="muted caption">City*</span>

                      <v-text-field
                        label="City*"
                        placeholder="City*"
                        class="pt-0"
                        :id="`autocompleteCity-${n - 1}`"
                        required
                        v-model="x.sub[n - 1].sourceCity"
                        :value="x.sub[n - 1].sourceCity"
                        single-line
                        @input="updateSourceCity(n)"
                      />
                      <span
                        v-if="x.sub[n - 1].sourceCityError"
                        style="color: red; text-align: center; font-size: 11px"
                        >Please Choose valid city.
                      </span>
                    </v-flex>

                    <v-flex class="pr-3" xs12 md3>
                      <span class="muted caption">Country*</span>
                      <v-autocomplete
                        label="Country*"
                        :items="countries"
                        item-text="country_name"
                        class="pt-0"
                        v-model="x.sub[n - 1].sourceCountry"
                        autocomplete="offfadsf"
                        single-line
                        :value="x.sub[n - 1].sourceCountry"
                        :rules="[rules.required]"
                        @change="updateSourceCountry(n)"
                        required
                      />

                      <!-- <v-text-field
                        label="Country*"
                        class="pt-0"
                        required
                        v-model="x.sub[n - 1].sourceCountry"
                        :value="x.sub[n - 1].sourceCountry"
                        :rules="[rules.required]"
                        single-line
                      /> -->
                    </v-flex>
                    <v-flex class="pr-3" xs12 md6>
                      <span class="muted caption">Address Name*</span>

                      <v-text-field
                        label="Address Name*"
                        class="pt-0"
                        required
                        v-model="x.sub[n - 1].sourceAddress"
                        :value="x.sub[n - 1].sourceAddress"
                        :rules="[rules.required]"
                        single-line
                      />
                    </v-flex>
                    <v-flex v-if="x.invoiceBy == 1" class="pr-3" xs12 md3>
                      <span class="muted caption">City* (in Arabic)</span>

                      <v-text-field
                        label="City* (in Arabic)"
                        class="pt-0"
                        required
                        v-model="x.sub[n - 1].arabicsourceCity"
                        :value="x.sub[n - 1].arabicsourceCity"
                        :rules="[
                          rules.noWhiteSpace,
                          rules.required,
                          rules.arabicLanguage,
                        ]"
                        single-line
                      />
                    </v-flex>
                    <v-flex v-if="x.invoiceBy == 1" class="pr-3" xs12 md3>
                      <span class="muted caption">Country* (in Arabic)</span>

                      <v-text-field
                        label="Country* (in Arabic)"
                        class="pt-0"
                        required
                        v-model="x.sub[n - 1].arabicsourceCountry"
                        :value="x.sub[n - 1].arabicsourceCountry"
                        :rules="[rules.required, rules.arabicLanguage]"
                        single-line
                      />
                    </v-flex>
                  </v-layout>
                  <hr class="divider-rule" />

                  <div class="heading primary-clr">Unloading Station (To)</div>
                  <v-layout row wrap>
                    <v-flex class="pr-3" xs12 md6>
                      <span class="muted caption"> Destination Address*</span>

                      <v-text-field
                        label="Address*"
                        class="pt-0"
                        :id="`autocomplete2-${n - 1}`"
                        placeholder="Search destination address*"
                        v-model="x.sub[n - 1].destinationLocation"
                        :value="x.sub[n - 1].destinationLocation"
                        autocomplete="offfadsf"
                        single-line
                        :rules="[rules.required]"
                      />
                    </v-flex>
                    <v-flex class="pr-3" xs12 md3>
                      <span class="muted caption">City*</span>

                      <v-text-field
                        label="City*"
                        placeholder="City*"
                        class="pt-0"
                        :id="`autocompleteUnloadCity-${n - 1}`"
                        required
                        v-model="x.sub[n - 1].destinationCity"
                        :value="x.sub[n - 1].destinationCity"
                        single-line
                        @input="updateDestinationCity(n)"
                      />
                      <span
                        v-if="x.sub[n - 1].destinationCityError"
                        style="color: red; text-align: center; font-size: 11px"
                        >Please Choose valid city.
                      </span>
                    </v-flex>
                    <v-flex class="pr-3" xs12 md3>
                      <span class="muted caption">Country*</span>
                      <v-autocomplete
                        label="Country*"
                        :items="countries"
                        item-text="country_name"
                        class="pt-0"
                        v-model="x.sub[n - 1].destinationCountry"
                        autocomplete="offfadsf"
                        single-line
                        :value="x.sub[n - 1].destinationCountry"
                        :rules="[rules.required]"
                        @change="updateDestinationCountry(n)"
                        required
                      />

                      <!-- <v-text-field
                        label="Country*"
                        class="pt-0"
                        required
                        v-model="x.sub[n - 1].destinationCountry"
                        :value="x.sub[n - 1].destinationCountry"
                        :rules="[rules.required]"
                        single-line
                      /> -->
                    </v-flex>
                    <v-flex class="pr-3" xs12 md6>
                      <span class="muted caption">Address Name*</span>

                      <v-text-field
                        label="Address Name*"
                        class="pt-0"
                        required
                        v-model="x.sub[n - 1].destinationAddress"
                        :value="x.sub[n - 1].destinationAddress"
                        :rules="[rules.required]"
                        single-line
                      />
                    </v-flex>
                    <v-flex class="pr-3" v-if="x.invoiceBy == 1" xs12 md3>
                      <span class="muted caption">City* (in Arabic)</span>

                      <v-text-field
                        label="City* (in Arabic)"
                        class="pt-0"
                        required
                        v-model="x.sub[n - 1].arabicdestinationCity"
                        :value="x.sub[n - 1].arabicdestinationCity"
                        single-line
                        :rules="[
                          rules.noWhiteSpace,
                          rules.required,

                          rules.arabicLanguage,
                        ]"
                      />
                    </v-flex>
                    <v-flex class="pr-3" v-if="x.invoiceBy == 1" xs12 md3>
                      <span class="muted caption">Country* (in Arabic)</span>

                      <v-text-field
                        label="Country* (in Arabic)"
                        class="pt-0"
                        required
                        v-model="x.sub[n - 1].arabicdestinationCountry"
                        :value="x.sub[n - 1].arabicdestinationCountry"
                        :rules="[rules.required, rules.arabicLanguage]"
                        single-line
                      />
                    </v-flex>
                  </v-layout>

                  <v-btn
                    v-if="n != 1"
                    color="orange darken-1"
                    style="color: white"
                    @click.native="backStep(n)"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    color="orange darken-1"
                    style="color: white"
                    @click.native="nextStep(n)"
                    v-if="n != 9"
                  >
                    Next
                  </v-btn>

                  <v-btn
                    v-if="n != 1 && n == steps"
                    color="orange darken-1"
                    style="color: white"
                    @click.native="deleteStep(n)"
                  >
                    Remove
                  </v-btn>
                  <div
                    class="my-3"
                    v-if="haserror"
                    style="
                      color: red;
                      width: 50%;
                      border: 0px;
                      margin: auto;
                      text-align: center;
                    "
                  >
                    {{ error }}
                  </div>

                  <!--<v-btn flat>Cancel</v-btn>-->
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </div>
          <hr class="divider-rule" />
          <div class="heading primary-clr">Additional Details</div>

          <v-layout row wrap>
            <v-flex class="pr-3" xs12 md4 v-if="x.customerCurrency == 'PKR'">
              <span class="muted caption">Contact Person Name*</span>
              <v-text-field
                label="Contact Person Name*"
                class="pt-0 currencyTitle"
                v-model="x.contactPersonName"
                :value="x.contactPersonName"
                :rules="[rules.noWhiteSpace, rules.required]"
                single-line
              />
            </v-flex>
            <v-flex class="pr-3" xs12 md4 v-if="x.customerCurrency == 'PKR'">
              <span class="muted caption">Address*</span>
              <v-text-field
                label="Address*"
                class="pt-0 currencyTitle"
                v-model="x.contactAddress"
                :value="x.contactAddress"
                :rules="[rules.noWhiteSpace, rules.required]"
                single-line
              />
            </v-flex>
            <v-flex class="pr-3" xs12 md10>
              <v-textarea
                rows="1"
                no-resize
                class="pt-0"
                label="Additional Notes"
                v-model="x.otherDetails"
                :value="x.otherDetails"
                single-line
              />
            </v-flex>

            <v-flex xs12 class="pr-3" sm6 md12>
              <div class="upload-btn-wrapper">
                <button v-if="x.proof.length < 5" class="btn">
                  + Upload Proof
                </button>
                <input
                  :disabled="loading"
                  ref="fileUpload"
                  type="file"
                  id="fileUpload"
                  multiple
                  accept=".pdf, .jpeg, .jpg, .png"
                  @change="proofChanged"
                />
              </div>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <ul>
                <li
                  :key="i"
                  v-for="(doc, i) in x.proof"
                  class="list"
                  ref="documentList"
                  style="
                    display: inline-block;
                    position: relative;
                    padding: 0;
                    margin-top: 10px;
                  "
                >
                  <button
                    type="button"
                    v-show="x.proof"
                    @click="removeImage(i)"
                    class="imageClose"
                  >
                    <v-icon color="white" size="18">close</v-icon>
                  </button>

                  <a
                    :href="doc"
                    class="document-link"
                    v-if="doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'"
                    target="_blank"
                  >
                    <img
                      src="../../assets/pfd.png"
                      style="padding-left: 10px"
                    />
                  </a>
                  <a :href="doc" class="document-link" target="_blank" v-else>
                    <img
                      src="../../assets/docimg.webp"
                      height="72"
                      width="75"
                    />
                  </a>
                </li>
              </ul>
            </v-flex>
            <v-flex
              md12
              xs12
              class="my-3"
              v-if="haserrors"
              style="
                color: red;
                width: 50%;
                border: 0px;
                margin: auto;
                text-align: center;
              "
            >
              {{ mainError }}
            </v-flex>
          </v-layout>

          <v-layout>
            <v-spacer />
            <v-btn flat class="px-3" @click.native="cancel()">Cancel</v-btn>
            <v-btn
              color="orange darken-1"
              @click.native="check()"
              style="color: white"
              :loading="loading"
              >Create Job</v-btn
            >
          </v-layout>
        </v-card-text>
      </v-form>

      <v-dialog
        v-model="dialog"
        persistent
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel2"
      >
        <v-card>
          <v-toolbar dark :color="options.color" dense flat>
            <v-toolbar-title class="white--text">Confirmation</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4">
            On clicking YES accepted rate and number of truck value will reset!
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-btn color="primary darken-1" text @click.native="agree(index)"
              >Yes</v-btn
            >
            <v-btn color="grey" text @click.native="cancel()">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialog1"
        persistent
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel1"
      >
        <v-card>
          <v-toolbar dark :color="options.color" dense flat>
            <v-toolbar-title class="white--text">Confirmation</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4">
            Are you sure you want to remove?
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-btn color="primary darken-1" text @click.native="agree1(index)"
              >Yes</v-btn
            >
            <v-btn color="grey" text @click.native="cancel1">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import { EventBus } from "../../event-bus.js";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import {
  NatureOfCargosAdd,
  TruckArray,
  PackingTypes,
  JobTypes,
  StorageKeys,
  AirArray,
  SeaArray,
  countries,
} from "../../constants/constants";
import moment from "moment";
let momentTz = require("moment-timezone");
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.min.css";
import CustomerCurrency from "@/components/Common/CustomerCurrency";
import { createJob, custDetails } from "../../constants/api-urls";
export default {
  created() {
    this.$eventBus.$on("stop-load", () => {
      this.loading = false;
    });
    this.checkBackDatePermission();
    this.openTimeDate = new Date();
    if (localStorage.getItem("workingCountries")) {
      let workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.x.customerCurrency = key.currency;
          this.workingCountry = key.value;
          this.fetchShippers(key.value);
        }
      });
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "jobs-profile-new") {
        this.$router.push(`/procurements`);
      }
    });

    // this.cargos = NatureOfCargosAdd;
    this.trucks = TruckArray;
    this.airTrucks = AirArray;
    this.seaTrucks = SeaArray;
    this.packings = PackingTypes;
    this.dialog = false;
    this.dialog1 = false;
    this.index = 1;
    this.jobTypeArray = [];
    this.jobTypes = [
      { text: "Land Freight", value: "1", disabled: false },
      { text: "Air Freight", value: "2", disabled: false },
      { text: "Sea Freight", value: "3", disabled: false },
    ];
    if (this.$route.params.id) {
      this.fillCustomerName();
    }

    return new Promise((resolve, reject) => {
      let script = document.createElement("script");
      script.onload = () => {
        this.initLoadingAutocomplete(0);
        this.initUnloadingAutocomplete(0);
        this.initLoadingCity(0);
        this.initUnLoadingCity(0);
      };
      script.src =
        "https://maps.google.com/maps/api/js?libraries=places&key=AIzaSyAbH5dlLsPapMreDphjjYtTbdj0_xHdLt8&sensor=false&region=US&language=en";
      document.head.appendChild(script);
    });
  },
  beforeDestroy() {
    this.$eventBus.$off("countryChanged");
  },
  components: {
    VueTimepicker,
    Loading,
    VueTimepicker,
    CustomerCurrency,
  },
  computed: {
    haserror() {
      return this.error !== null;
    },
    haserrors() {
      return this.mainError !== null;
    },
  },
  mounted: {
    initializeMap: {},
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
    e1(val) {
      console.log(val, "e1");
    },
  },
  data() {
    return {
      valid: true,
      operationName: "",
      realbookAddress: "",
      openTimeDate: "",
      closeTimeDate: "",
      backDateRole: false,
      workinCountry: "",
      weightInTons: "",
      countries: countries,
      e1: 1,
      steps: 1,
      editable: true,
      lazy: false,
      errorType: false,
      dateToday: moment(new Date()).format("YYYY-MM-DD"),
      yourFormat: "HH:mm A",
      pickupTime: "",
      ownerName: "",
      customerId: "",
      customers: [],
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      dialog: false,
      dialog1: false,
      index: 0,
      isAssignment: false,
      sourceCityGoogle: false,

      x: {
        otherDetails: null,
        proof: [],
        customerId: null,
        customerCurrency: null,
        packingType: null,
        commodity: null,
        countryCode: null,
        chargesType: "Exclusive",
        contactPersonName: null,
        contactAddress: null,
        customerAddress: null,
        vatNumber: null,
        invoiceBy: null,

        sub: [
          {
            jobType: null,
            jobBooking: null,
            jobGeoType: null,
            jobMode: null,
            loadType: null,
            jobPrice: null,
            customerId: null,
            customerCurrency: null,
            packingType: null,
            commodity: null,
            truckType: null,
            selectedIndustry: null,
            priceType: null,
            assignmentjobPrice: 0,
            sourceLocation: null,
            arabicsourceLocation: null,
            sourceAddress: null,
            arabicsourceAddress: null,
            sourceCity: null,
            sourceCityError: null,
            destinationCityError: null,
            arabicsourceCity: null,
            sourceCountry: "",
            arabicsourceCountry: "",
            destinationLocation: null,
            arabicdestinationLocation: null,
            destinationAddress: null,
            arabicdestinationAddress: null,
            destinationCity: null,
            arabicdestinationCity: null,
            destinationCountry: "",
            arabicdestinationCountry: "",
            sourcelong: null,
            sourcelat: "",
            destinationlong: null,
            destinationlat: null,
            isAssignment: false,
            numberOfAssignmentsRequired: 1,
            startDate: 0,
            pickupTime: null,
            priceTypeError: false,
            startDateSelect: null,
            errorType: false,
            jobStartDatePicker: null,
            jobModeError: false,
            loadTypeError: false,
            jobBookingError: false,
            jobStartDateBool: false,
            jobGeoTypeError: false,
            seaTrucksSub: null,
            airTrucksSub: null,
            trucksSub: null,
            truckTypeParent: null,
            invoicingCity: null,
          },
        ],
      },
      cargos: [],
      trucks: [],
      trucksSub: [],

      airTrucks: [],
      seaTrucks: [],
      seaTrucksSub: [],
      airTrucksSub: [],
      packings: [],
      date: null,
      jobStartDatePicker: null,

      jobPickupTimeBool: false,
      modal: false,
      loading: false,
      error: null,
      mainError: null,
      jobTypes: [
        { text: "Land Freight", value: "1", disabled: false },
        { text: "Air Freight", value: "2", disabled: false },
        { text: "Sea Freight", value: "3", disabled: false },
      ],
      jobTypeArray: [],
      rules: {
        required: (value) => !!value || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "Enter valid value.",
        languageRule: (v) => /^[ A-Za-z]*$/.test(v) || "Enter valid value",
        vatNumber: (v) =>
          /^([a-zA-Z0-9_-]){1,20}$/.test(v) || "Please Enter Valid Value",
        decmialAllowNumber2Float: (v) =>
          /^\d+\.\d{0,2}$/.test(v) || "Please Enter Valid Value",
        requiredLocation: (value) =>
          (!!value && !!value.length) || "This field is required.",

        counter: (value) =>
          (value != null && value.trim().length <= 20) ||
          "Number should contain maximum 20 characters.",
        password: (v) =>
          /^(?!.* ).{6,15}$/ ||
          "Password length must be of minimum 6 characters",
        min: (v) => v >= 1 || `Value must be at least 1`,
        max: (v) => v <= 100 || `Value may not be greater than 100.`,
        number: (v) => /^[0-9]*$/.test(v) || "Please Enter Valid Price",
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
        userName: (v) =>
          /^[a-z0-9_-]{6,15}$/.test(v) ||
          "Username must be of minimum 6 characters.",
        companyName: (v) =>
          /^[a-z0-9_-]{3,15}$/.test(v) || "Please Enter Valid company name.",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        arabicLanguage: (v) =>
          /^([أ-ي ]| [\u0600-\u06FF])+$/.test(v) ||
          "Please Enter arabic language",
        decmialAllowNumberFloat: (v) =>
          /^\d*\.?\d*$/.test(v) || "Please Enter Valid Value",
        intRule: (v) => {
          for (var i = 0; i < v.length; i++) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v[i])) {
              return "Insert valid email";
            }
            v[i] = parseInt(v[i]);
          }
          return true;
        },
        number: (v) => /^[0-9]*$/.test(v) || "Please Enter Valid Rate",
      },
    };
  },
  mounted: function () {
    setTimeout(() => {}, 4000);
  },
  methods: {
    test(e) {},

    getformFillDuration() {
      var diff = Math.abs(this.openTimeDate - this.closeTimeDate) / 1000; // hours
      var hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      var minutes =
        Math.floor(diff / 60) % 60 < 10
          ? "0" + (Math.floor(diff / 60) % 60)
          : Math.floor(diff / 60) % 60;
      var seconds =
        diff % 60 < 10 ? "0" + Math.round(diff % 60) : Math.round(diff % 60);
      return minutes + ":" + seconds;
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "Active";
        case 2:
          return "Completed";
        case 3:
          return "Customer Rate Accepted";
        case 4:
          return "Finding Drivers";
        case 5:
          return "Driver Assigned";
        case 6:
          return "In-Transit";
        case 7:
          return "Shipment Offloaded";
        case 8:
          return "Payment Pending";
        case 9:
          return "Payment Done";
        case 10:
          return "Low Credit Limit";

        case -1:
          return "Cancelled";
        default:
          return "Unknown";
      }
    },
    removeImage(key) {
      this.mainError = null;
      this.x.proof.splice(key, 1);
    },
    updateSourceCity(n) {
      if (this.x.sub[n - 1].sourceCityError == false) {
        this.x.sub[n - 1].sourceCityError = true;
      }
      console.log(this.x.sub[n - 1].sourceCity);

      // this.axios
      //   .get("https://maps.googleapis.com/maps/api/place/autocomplete/json", {
      //     params: {
      //       input: this.x.sub[n - 1].sourceCity,
      //       key: "AIzaSyAbH5dlLsPapMreDphjjYtTbdj0_xHdLt8", // Replace with your actual API key
      //     },
      //   })
      //   .then((response) => {
      //     console.log(response);
      //   })
      //   .catch((error) => {
      //     console.error("Error fetching predictions:", error);
      //   });
    },
    updateDestinationCity(n) {
      if (this.x.sub[n - 1].destinationCityError == false) {
        this.x.sub[n - 1].destinationCityError = true;
      }
      // console.log(this.x.sub[n - 1].sourceCity);
    },
    updateDestinationCountry(n) {
      this.x.sub[n - 1].destinationCityError = true;
      this.x.sub[n - 1].destinationCity = "";
    },
    updateSourceCountry(n) {
      this.x.sub[n - 1].sourceCity = "";
      this.x.sub[n - 1].sourceCityError = true;
    },
    async check() {
      this.loading = true;
      this.operationName = "add-new-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.create();
      } else {
        this.loading = false;
        return;
      }
    },
    checkBackDatePermission() {
      let userPermissions = JSON.parse(
        localStorage.getItem("permissions")
      ).permissions;
      userPermissions.filter((x) => {
        if (x.operationName == "back-date-jobs") {
          this.backDateRole = true;
        }
      });
    },

    fetchShippers(val) {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.t = config.headers.authorization;
      let body = { workingCountry: [val], section: "job" };
      this.axios
        .post(`${this.constants.apiUrl}/dmsAdmin/shippers`, body, config)
        .then((response) => {
          const { data = null } = response.data;
          for (var i = 0; i < data.length; i++) {
            data[i].fullName = data[i].name + "(" + data[i].companyName + ")";
          }

          data.forEach((element) => {
            if (element.companyName) {
              this.customers.push({
                text: element.fullName,
                value: element.userId,
                companyName: element.companyName,
                companyVatNumber: element.companyVatNumber,
                address: element.address
                  ? element.address.replaceAll("\n", ", ")
                  : "",
                invoicingCity: element.invoicingCity,
                invoicingCountry: element.invoicingCountry,
                selectedIndustry: element.selectedIndustry,
              });
            } else {
              this.customers.push({
                text: element.name,
                value: element.userId,
                companyName: element.companyName,
                companyVatNumber: element.companyVatNumber,
                address: element.address
                  ? element.address.replaceAll("\n", ", ")
                  : "",
                invoicingCity: element.invoicingCity,
                invoicingCountry: element.invoicingCountry,
                selectedIndustry: element.selectedIndustry,
              });
            }
          });
        });
    },
    truckCat(val, n) {
      var x = this.trucks.filter((v) => {
        if (v.value === val) return v;
      });
      this.trucksSub = x[0].sub;
      this.x.sub[n - 1].truckType = null;
      this.x.sub[n - 1].trucksSub = x[0].sub;
    },
    airCat(val, n) {
      var x = this.airTrucks.filter((v) => {
        if (v.value === val) return v;
      });
      this.airTrucksSub = x[0].sub;
      this.x.sub[n - 1].truckType = null;
      this.x.sub[n - 1].airTrucksSub = x[0].sub;
    },
    seaCat(val, n) {
      var x = this.seaTrucks.filter((v) => {
        if (v.value === val) return v;
      });
      this.seaTrucksSub = x[0].sub;
      this.x.sub[n - 1].truckType = null;
      this.x.sub[n - 1].seaTrucksSub = x[0].sub;
    },
    jobStartDatePicker1(val, n) {
      //debugger
      const epoch = moment(val, "YYYY-MM-DD").unix();
      //debugger
      this.x.sub[n - 1].startDate = epoch;
    },
    jobPickupTimePicker(val, n) {
      this.x.sub[n - 1].pickupTime = val.displayTime;
      this.x.sub[n - 1].pickupTimeError = false;
    },
    reSetJobMode(value, n) {
      if (value == 1) {
        this.x.sub[n - 1].jobMode = null;
      }
    },
    fetchIndustries() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      const url = `${this.constants.apiUrl}/website/industries`;
      this.axios.get(url, config).then((response) => {
        console.log(response.data.data);
        this.fetchIndustriesList = response.data.data;
      });
    },
    setCustomerValues(val) {
      if (val) {
        this.cargos = [];

        // this.cargos = NatureOfCargosAdd;
        this.x.selectedIndustry = null;
        var data = this.customers.filter((v) => {
          if (v.value === val) return v;
        });
        this.x.vatNumber = data[0].companyVatNumber;
        console.log(this.cargos);
        if (data[0].selectedIndustry.length) {
          var list = [];
          console.log(data[0].selectedIndustry);

          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          let config = {
            headers: {
              authorization: `bearer ${token}`,
            },
          };

          const url = `${this.constants.apiUrl}/website/industries`;
          this.axios.get(url, config).then((response) => {
            console.log(response.data.data);
            let e = [];
            e = response.data.data;
            data[0].selectedIndustry.forEach((v) => {
              console.log(v);
              e.forEach((t) => {
                console.log(t);
                if (t.numericIdentifier == v) {
                  console.log("inside popup");
                  list.push({ numericIdentifier: v, name: t.name });
                }
              });
            });
          });

          console.log(list);
          this.cargos = list;
          this.x.selectedIndustry = data[0].selectedIndustry[0];
        } else {
          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          let config = {
            headers: {
              authorization: `bearer ${token}`,
            },
          };

          const url = `${this.constants.apiUrl}/website/industries`;
          this.axios.get(url, config).then((response) => {
            console.log(response.data.data);
            let e = [];
            e = response.data.data;
            this.cargos = [];
            this.cargos = response.data.data;
            this.x.selectedIndustry = null;
          });
        }
        this.x.customerAddress = data[0].address;
        this.x.invoiceBy = data[0].invoicingCountry
          ? data[0].invoicingCountry.toString()
          : "";
        this.x.invoicingCity = data[0].invoicingCity
          ? data[0].invoicingCity.toString()
          : "";
      }

      this.SetAddress(this.x.customerId);
    },
    // checkJobMode(jobgeoType,sourceCountry,destinationCountry,n){

    //   if(sourceCountry && destinationCountry){
    //     if(sourceCountry===destinationCountry){
    //       if(jobgeoType==2){
    //         this.error= null
    //         return
    //       }else{
    //          this.error = "please check job geographer"
    //          this.reSetJobMode(jobgeoType, n)

    //       }

    //     }else {
    //       if(jobgeoType==1){
    //         this.error= null
    //         return
    //       }else{
    //          this.error = "please check job geographer"
    //          this.reSetJobMode(jobgeoType, n)

    //       }

    //     }

    //   }else {
    //     return;
    //   }

    // },
    SetAddress(val) {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = { customerId: val };
      this.axios
        .post(
          `${this.constants.apiUrl}/dmsAdmin/shippersLastJobAddress`,
          body,
          config
        )
        .then((response) => {
          const size = this.x.sub.length;

          for (var n = 0; n < size; n++) {
            this.x.sub[n].sourceLocation = response.data.data[0].sourceLocation;
            this.x.sub[n].sourceAddress = response.data.data[0].sourceAddress;
            this.x.sub[n].sourceCountry = response.data.data[0].sourceCountry;
            this.x.sub[n].sourceCity = response.data.data[0].sourceCity;
            if (response.data.data[0].sourceCity) {
              this.x.sub[n].sourceCityError = false;
            }
            this.x.sub[n].sourcelat = response.data.data[0].sourceLatLong[1];
            this.x.sub[n].sourcelong = response.data.data[0].sourceLatLong[0];
            this.x.sub[n].arabicsourceCity =
              response.data.data[0].sourceCityArabic;
            this.x.sub[n].arabicsourceCountry =
              response.data.data[0].sourceCountryArabic;
            this.x.sub[n].arabicdestinationCountry =
              response.data.data[0].destinationCountryArabic;
            this.x.sub[n].arabicdestinationCity =
              response.data.data[0].destinationCityArabic;

            this.x.sub[n].destinationLocation =
              response.data.data[0].destinationLocation;
            this.x.sub[n].destinationAddress =
              response.data.data[0].destinationAddress;
            this.x.sub[n].destinationCountry =
              response.data.data[0].destinationCountry;
            this.x.sub[n].destinationCity =
              response.data.data[0].destinationCity;
            if (response.data.data[0].destinationCity) {
              this.x.sub[n].destinationCityError = false;
            }
            this.x.sub[n].destinationlat =
              response.data.data[0].destinationLatLong[1];
            this.x.sub[n].destinationlong =
              response.data.data[0].destinationLatLong[0];
          }
        });
    },
    nextStep(n) {
      // this.validationCheck(n-1);
      console.log(n);
      console.log(this.steps);
      console.log(this.e1);
      if (
        this.x.sub[n - 1].sourceCityError == null ||
        this.x.sub[n - 1].sourceCityError == true
      ) {
        this.error = "Please choose  valid city ";
        return;
      }
      if (
        this.x.sub[n - 1].destinationCityError == null ||
        this.x.sub[n - 1].destinationCityError == true
      ) {
        this.error = "Please choose valid city";
        return;
      }

      if (n === this.steps) {
        this.x.sub[n - 1].pickupTimeError = false;
        if (this.x.sub[n - 1].pickupTime == null) {
          this.x.sub[n - 1].pickupTimeError = true;
        } else {
          if (
            this.x.sub[n - 1].pickupTime.mm != null &&
            this.x.sub[n - 1].pickupTime.hh != null
          ) {
            this.x.sub[n - 1].pickupTimeError = false;
          }
        }

        if (this.$refs.form.validate()) {
          if (this.steps < 9) {
            this.steps = this.steps + 1;
            this.e1 = n + 1;
            this.x.sub.push({
              jobType: null,
              jobBooking: null,
              jobGeoType: null,
              jobMode: null,
              loadType: null,
              jobPrice: null,
              customerId: null,
              customerCurrency: null,
              packingType: null,
              commodity: null,
              truckType: null,
              selectedIndustry: null,
              priceType: null,
              assignmentjobPrice: 0,
              sourceLocation: null,
              arabicsourceLocation: null,
              sourceAddress: null,
              arabicsourceAddress: null,
              sourceCity: null,
              arabicsourceCity: null,
              sourceCountry: "",
              arabicsourceCountry: "",
              destinationLocation: null,
              arabicdestinationLocation: null,
              destinationAddress: null,
              arabicdestinationAddress: null,
              destinationCity: null,
              arabicdestinationCity: null,
              destinationCountry: "",
              arabicdestinationCountry: "",
              sourcelong: null,
              sourcelat: "",
              destinationlong: null,
              destinationlat: null,
              isAssignment: false,
              otherDetails: null,
              numberOfAssignmentsRequired: 1,
              startDate: 0,
              pickupTime: null,
              pickupTimeError: null,
              sourceCityError: null,
              destinationCityError: null,
              jobStartDatePicker: null,
              jobModeError: false,
              loadTypeError: false,
              jobBookingError: false,
              priceTypeError: false,
              jobGeoTypeError: false,
              seaTrucksSub: null,
              airTrucksSub: null,
              trucksSub: null,
              truckTypeParent: null,
              invoicingCity: null,
            });
            return new Promise((resolve, reject) => {
              let script = document.createElement("script");
              script.onload = () => {
                this.initLoadingAutocomplete(n);
                this.initUnloadingAutocomplete(n);
                this.initLoadingCity(n);
                this.initUnLoadingCity(n);
              };
              script.src =
                "https://maps.google.com/maps/api/js?libraries=places&key=AIzaSyAbH5dlLsPapMreDphjjYtTbdj0_xHdLt8&sensor=false&region=US&language=en";
              document.head.appendChild(script);
            });
          }
          window.scrollTo(0, 500);
        } else {
          window.scrollTo(0, 0);
        }
      } else {
        this.e1 = n + 1;
      }
    },
    backStep(n) {
      if (n === this.steps) {
        this.e1 = n - 1;
        this.x.sub[n - 1].pickupTimeError = false;
      } else if (this.steps != 1) {
        this.e1 = n - 1;
        this.x.sub[n - 1].pickupTimeError = false;
      } else {
        this.e1 = 1;
      }
    },
    deleteStep(n) {
      this.dialog1 = true;
      this.index = n;
    },
    deleteStepConfirmed(n) {
      if (n === this.steps) {
        this.e1 = n - 1;
        this.x.sub.pop(n - 1);
        this.jobTypeArray.pop(n - 1);
        this.steps = this.steps - 1;
        //this.x.sub[n-1].pickupTimeError = false;
      } else {
        this.e1 = 1;
      }
    },
    checkTime() {
      let re = /^\d{1,2}:\d{2}([ap]m)?$/;

      if (this.pickupTime != "" && !this.pickupTime.match(re)) {
        this.error = "Please select the valid pickup time before moving ahead!";
        pickupTime.focus();
        return false;
      }
    },
    // allowedDates(){ val =>new Date()},

    setShipmentValues(value, n) {
      //  debugger
      var i = parseInt(value);
      var index = this.jobTypeArray.indexOf(value);
      this.jobTypeArray[n - 1] = value;

      this.x.sub[n - 1].numberOfAssignmentsRequired = 1;

      if (value == "1") {
        // this.truckCat = null;
        this.x.sub[n - 1].truckTypeParent = [];
        this.x.sub[n - 1].trucksSub = [];
      }
      if (value == "2") {
        //this.airCat = null;
        this.x.sub[n - 1].truckTypeParent = [];
        this.x.sub[n - 1].airTrucksSub = [];
      }
      if (value == "3") {
        // this.seaCat = null;
        this.x.sub[n - 1].truckTypeParent = [];
        this.x.sub[n - 1].seaTrucksSub = [];
      }
      if (this.x.sub[n - 1].jobMode == 3) {
        this.x.sub[n - 1].jobMode = null;
      }
    },
    checkPriceType(value, n) {
      if (this.x.sub[n - 1].assignmentjobPrice) {
        this.index = n;
        this.dialog = true;
      }
      this.errorType = false;
      this.x.sub[n - 1].isAssignment = value == "assignment" ? true : false;
      if (this.x.sub[n - 1].isAssignment) {
        this.x.sub[n - 1].assignmentjobPrice =
          this.x.sub[n - 1].jobPrice *
          this.x.sub[n - 1].numberOfAssignmentsRequired;
      }
    },
    setJobPrice(n) {
      if (!this.x.sub[n - 1].priceType) {
        this.errorType = true;
        this.loading = false;
        return;
      } else {
        if (this.x.sub[n - 1].isAssignment) {
          this.errorType = false;
          this.x.sub[n - 1].assignmentjobPrice =
            this.x.sub[n - 1].jobPrice *
            this.x.sub[n - 1].numberOfAssignmentsRequired;
        }
      }
    },

    allowedStep: (m) => m % 30 === 0,
    initLoadingCity(n) {
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      var autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocompleteCity-" + n)
      );

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      // autocomplete.setFields(['address_component', 'geometry']);

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.

      autocomplete.addListener("place_changed", () => {
        // Get the place details from the autocomplete object.
        var place = autocomplete.getPlace();

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.

        // this.x.sub[n].sourceLocation = place.formatted_address;
        // this.x.sub[n].sourceAddress = place.name;
        // this.x.sub[n].sourcelat = place.geometry.location.lat();
        // this.x.sub[n].sourcelong = place.geometry.location.lng();

        var componentMap = {
          country: "country",
          locality: "locality",
        };
        var componentMap2 = {
          administrative_area_level_1: "administrative_area_level_1",
        };
        var x = [];
        var y = [];
        if (place.address_components) {
          this.x.sub[n].sourceCityError = false;
        } else {
          this.x.sub[n].sourceCityError = true;
        }
        // if (!place) {
        //   this.sourceCityGoogle = true;
        // }
        console.log(place.address_components);

        for (var i = 0; i < place.address_components.length; i++) {
          var types = place.address_components[i].types; // get types array of each component
          for (var j = 0; j < types.length; j++) {
            // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above
            var component_type = types[j];
            // check if this type is in your component map.If so that means you want this component
            if (componentMap.hasOwnProperty(component_type)) {
              x.push(place.address_components[i]["long_name"]);
            }
            if (componentMap2.hasOwnProperty(component_type)) {
              y.push(place.address_components[i]["long_name"]);
            }
          }
        }
        if (x.length === 2) {
          this.x.sub[n].sourceCountry = x[1];
          this.x.sub[n].sourceCity = x[0];
          // this.x.sub[n].arabicsourceCountry = x[1];
          // this.x.sub[n].arabicsourceCity = x[0];
        } else {
          // this.x.sub[n].sourceCountry = y[1];
          this.x.sub[n].sourceCity = y[0];
          if (y.length == 0) {
            this.x.sub[n].sourceCityError = true;
          }
        }
        if (!this.x.sub[n].sourceCity.match(/^[ A-Za-z0-9_@./#&+-]*$/)) {
          this.x.sub[n].sourceCity = "";
          this.x.sub[n].sourceCityError = true;
        }
      });
    },
    initUnLoadingCity(n) {
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      var autocomplete2 = new google.maps.places.Autocomplete(
        document.getElementById("autocompleteUnloadCity-" + n)
      );

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      // autocomplete2.setFields(['address_component', 'geometry']);

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      autocomplete2.addListener("place_changed", () => {
        // Get the place details from the autocomplete object.
        var place = autocomplete2.getPlace();

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.

        // this.x.sub[n].destinationLocation = place.formatted_address;
        // this.x.sub[n].destinationAddress = place.name;
        // this.x.sub[n].destinationlong = place.geometry.location.lng();
        // this.x.sub[n].destinationlat = place.geometry.location.lat();

        var componentMap = {
          country: "country",
          locality: "locality",
        };
        var componentMap2 = {
          administrative_area_level_1: "administrative_area_level_1",
        };
        var x = [];
        var y = [];
        if (place.address_components) {
          this.x.sub[n].destinationCityError = false;
        } else {
          this.x.sub[n].destinationCityError = true;
        }

        for (var i = 0; i < place.address_components.length; i++) {
          var types = place.address_components[i].types; // get types array of each component
          for (var j = 0; j < types.length; j++) {
            // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above
            var component_type = types[j];
            // check if this type is in your component map.If so that means you want this component
            if (componentMap.hasOwnProperty(component_type)) {
              x.push(place.address_components[i]["long_name"]);
            }
            if (componentMap2.hasOwnProperty(component_type)) {
              y.push(place.address_components[i]["long_name"]);
            }
          }
        }

        if (x.length === 2) {
          this.x.sub[n].destinationCountry = x[1];
          this.x.sub[n].destinationCity = x[0];
        } else {
          if (y.length == 0) {
            this.x.sub[n].destinationCityError = true;
          }
          this.x.sub[n].destinationCity = y[0];
        }
        if (!this.x.sub[n].destinationCity.match(/^[ A-Za-z0-9_@./#&+-]*$/)) {
          this.x.sub[n].destinationCity = "";
          this.x.sub[n].destinationCityError = true;
        }
      });
    },
    initLoadingAutocomplete(n) {
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      var autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete1-" + n)
      );

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      // autocomplete.setFields(['address_component', 'geometry']);

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.

      autocomplete.addListener("place_changed", () => {
        // Get the place details from the autocomplete object.
        var place = autocomplete.getPlace();
        if (place.address_components) {
          this.x.sub[n].destinationCityError = false;
        } else {
          this.x.sub[n].destinationCityError = true;
        }
        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.

        this.x.sub[n].sourceLocation = place.formatted_address;
        this.x.sub[n].sourceAddress = place.name;
        this.x.sub[n].sourcelat = place.geometry.location.lat();
        this.x.sub[n].sourcelong = place.geometry.location.lng();

        var componentMap = {
          country: "country",
          locality: "locality",
        };
        var componentMap2 = {
          administrative_area_level_1: "administrative_area_level_1",
        };
        var x = [];
        var y = [];
        if (place.address_components) {
          this.x.sub[n].sourceCityError = false;
        } else {
          this.x.sub[n].sourceCityError = true;
        }

        for (var i = 0; i < place.address_components.length; i++) {
          var types = place.address_components[i].types; // get types array of each component
          for (var j = 0; j < types.length; j++) {
            // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above
            var component_type = types[j];
            // check if this type is in your component map.If so that means you want this component
            if (componentMap.hasOwnProperty(component_type)) {
              x.push(place.address_components[i]["long_name"]);
            }
            if (componentMap2.hasOwnProperty(component_type)) {
              y.push(place.address_components[i]["long_name"]);
            }
          }
        }
        if (x.length === 2) {
          this.x.sub[n].sourceCountry = x[1];
          this.x.sub[n].sourceCity = x[0];
          // this.x.sub[n].arabicsourceCountry = x[1];
          // this.x.sub[n].arabicsourceCity = x[0];
        } else {
          if (y.length == 0) {
            this.x.sub[n].sourceCityError = true;
          }
          this.x.sub[n].sourceCity = y[0];
        }
        if (!this.x.sub[n].sourceCity.match(/^[ A-Za-z0-9_@./#&+-]*$/)) {
          this.x.sub[n].sourceCity = "";
          this.x.sub[n].sourceCityError = true;
        }
      });
    },
    initUnloadingAutocomplete(n) {
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      var autocomplete2 = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete2-" + n)
      );

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      // autocomplete2.setFields(['address_component', 'geometry']);

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      autocomplete2.addListener("place_changed", () => {
        // Get the place details from the autocomplete object.
        var place = autocomplete2.getPlace();

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.

        this.x.sub[n].destinationLocation = place.formatted_address;
        this.x.sub[n].destinationAddress = place.name;
        this.x.sub[n].destinationlong = place.geometry.location.lng();
        this.x.sub[n].destinationlat = place.geometry.location.lat();

        var componentMap = {
          country: "country",
          locality: "locality",
        };
        var componentMap2 = {
          administrative_area_level_1: "administrative_area_level_1",
        };
        var x = [];
        var y = [];
        if (place.address_components) {
          this.x.sub[n].destinationCityError = false;
        } else {
          this.x.sub[n].destinationCityError = true;
        }

        for (var i = 0; i < place.address_components.length; i++) {
          var types = place.address_components[i].types; // get types array of each component
          for (var j = 0; j < types.length; j++) {
            // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above
            var component_type = types[j];
            // check if this type is in your component map.If so that means you want this component
            if (componentMap.hasOwnProperty(component_type)) {
              x.push(place.address_components[i]["long_name"]);
            }
            if (componentMap2.hasOwnProperty(component_type)) {
              y.push(place.address_components[i]["long_name"]);
            }
          }
        }

        if (x.length === 2) {
          this.x.sub[n].destinationCountry = x[1];
          this.x.sub[n].destinationCity = x[0];
        } else {
          if (y.length == 0) {
            this.x.sub[n].destinationCityError = true;
          }
          this.x.sub[n].destinationCity = y[0];
        }
        if (!this.x.sub[n].destinationCity.match(/^[ A-Za-z0-9_@./#&+-]*$/)) {
          this.x.sub[n].destinationCity = "";
          this.x.sub[n].destinationCityError = true;
        }
      });
    },
    getText(arr = [], val) {
      if (val && arr.length > 0) {
        const el = arr.find((el) => el.value === val);
        return el ? el.text : "NA";
      }
      return "NA";
    },
    cancel() {
      this.$router.go(-1);
    },
    proofChanged(e) {
      this.error = null;
      var files = e.target.files || e.dataTransfer.files;
      let x = [...files];
      setTimeout(() => {
        this.$refs.fileUpload.value = "";
      }, 6000);
      if (!files.length) return;

      Array.from(x).forEach((y, i) => {
        this.createImage(files[i]);
      });
    },
    createImage(file) {
      //this.$refs.fileUpload.value = "";
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.mainError = "Please upload proof with size less than 5MB!";

        return false;
      }
      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !==
          "application/pdf"
      ) {
        this.mainError =
          "Please upload proof of file type png , jpg ,jpeg or pdf!";
        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.upload(file);
      }
    },
    upload(file) {
      this.loading = true;
      const formData = new FormData();
      formData.append("image", file);
      this.axios({
        url: `${this.constants.apiUrl}/dmsAdmin/uploadImage`,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          const { statusCode } = response.data;
          if (statusCode === 200) {
            this.loading = false;
            if (this.x.proof.length < 5) {
              this.x.proof.push(response.data.data.original);
            } else {
              this.mainError = "Please upload only 5 proofs!";
              return false;
            }
          } else {
            this.error = "Failed to upload image";
          }
          this.loading = false;
        },
        (error) => {
          this.error = "Failed to upload image";
          this.loading = false;
        }
      );
    },
    async create() {
      if (this.backDateRole) {
        this.mainError = null;
        this.operationName = "back-date-jobs";
        let y = await this.checkOpertaionPermission(this.operationName);
      }
      let timezone = momentTz.tz.guess();
      if (this.customerId) {
        this.x.customerId = this.customerId;
      }
      const { serverUrl } = this.constants;
      const size = this.x.sub.length;
      this.loading = true;
      var assignments = [];
      var totalAssignmentsRequired = 0;
      var totalJobPrice = 0;
      var jobTypeArray = [];
      var JobGeoTypeArray = [];
      var jobModeArray = [];
      var loadModeArray = [];
      this.error = null;

      for (var n = 0; n < size; n++) {
        this.x.sub[n].pickupTimeError = false;

        if (this.x.sub[n].pickupTime == null) {
          this.x.sub[n].pickupTimeError = true;
          this.error = "Please enter sub job details!";
          this.loading = false;
        } else {
          if (
            this.x.sub[n].pickupTime.mm != null &&
            this.x.sub[n].pickupTime.hh != null
          ) {
            this.x.sub[n].pickupTimeError = false;
          }
        }
        if (
          this.x.sub[n].sourceCityError == null ||
          this.x.sub[n].sourceCityError == true
        ) {
          this.x.sub[n].sourceCityError = true;
          this.error = "Please choose valid city ";
          this.loading = false;
          return;
        }
        if (
          this.x.sub[n].destinationCityError == null ||
          this.x.sub[n].destinationCityError == true
        ) {
          this.x.sub[n].destinationCityError = true;
          this.error = "Please choose valid city ";
          this.loading = false;
          return;
        }
      }
      if (this.$refs.form.validate()) {
        this.loading = true;
        for (var n = 0; n < size; n++) {
          var pickupTime = "";
          let re = /^\d{1,2}:\d{2}([ap]m)?$/;
          if (this.x.sub[n].pickupTime) {
            if (!this.x.sub[n].pickupTime.match(re)) {
              this.loading = false;
              this.error =
                "Please select the valid pickup time before moving ahead!";
              this.x.sub[n].pickupTime.focus();
              return false;
            } else {
              pickupTime = this.x.sub[n].pickupTime;
            }
          }
          // if (
          //   !this.x.sub[n].destinationlat ||
          //   !this.x.sub[n].destinationlong ||
          //   !this.x.sub[n].sourcelat ||
          //   !this.x.sub[n].sourcelong
          // ) {
          //   this.loading = false;
          //   this.error = "Please select the valid address";
          //   return false;
          // }
          var tempTime = new Date(this.x.sub[n].startDateSelect);
          var year = tempTime.getFullYear();
          var month = tempTime.getMonth();
          var day = tempTime.getDate();
          var start_date_time = new Date(
            year,
            month,
            day,
            parseInt(this.x.sub[n].pickupTime.split(":")[0]),
            parseInt(this.x.sub[n].pickupTime.split(":")[1])
          );

          // var start_date_time = new Date(
          //   year + "-" + month + "-" + day + " " + this.x.sub[n].pickupTime
          // );
          start_date_time = new Date(start_date_time).toISOString();

          var start_Date =
            this.x.sub[n].startDateSelect + " " + this.x.sub[n].pickupTime;
          const epoch = moment(start_Date).unix();
          var start_date = epoch;

          var commodity = "";
          if (this.x.commodity) {
            commodity = this.x.commodity.trim();
          }

          var perAssignmentPrice = 0;
          var jobPrice = 0;
          if (
            this.x.sub[n].jobGeoType == 2 &&
            this.x.sub[n].sourceCountry !== this.x.sub[n].destinationCountry
          ) {
            this.error = "";
            this.error =
              "Please select same country address because your job geo type is domestic";
            this.loading = false;
            return false;
          }
          if (
            this.x.sub[n].jobGeoType == 1 &&
            this.x.sub[n].sourceCountry == this.x.sub[n].destinationCountry
          ) {
            this.error = "";
            this.error =
              "Please select different country address because your job geo type is international";
            this.loading = false;
            return false;
          }
          if (this.x.sub[n].priceType == "assignment") {
            perAssignmentPrice = Number(this.x.sub[n].jobPrice)
              .toFixed(2)
              .toString();
            jobPrice = Number(this.x.sub[n].assignmentjobPrice)
              .toFixed(2)
              .toString();
          }

          if (this.x.sub[n].priceType == "job") {
            jobPrice = Number(this.x.sub[n].jobPrice).toFixed(2).toString();

            perAssignmentPrice =
              Number(this.x.sub[n].jobPrice).toFixed(2).toString() /
              this.x.sub[n].numberOfAssignmentsRequired;
          }
          totalJobPrice = Number(totalJobPrice) + Number(jobPrice);
          this.x.sub[n].numberOfAssignmentsRequired = Number(
            this.x.sub[n].numberOfAssignmentsRequired
          );

          totalAssignmentsRequired =
            totalAssignmentsRequired +
            this.x.sub[n].numberOfAssignmentsRequired;
          jobTypeArray.push(this.x.sub[n].jobType);
          JobGeoTypeArray.push(this.x.sub[n].jobGeoType);
          jobModeArray.push(this.x.sub[n].jobMode);
          loadModeArray.push(this.x.sub[n].loadType);
          var assignment = {
            sourceCity: this.x.sub[n].sourceCity.trim(),
            workingCountry: this.workingCountry,

            sourceCountry: this.x.sub[n].sourceCountry.trim(),

            sourceLocation: this.x.sub[n].sourceLocation.trim(),
            sourceAddress: this.x.sub[n].sourceAddress.trim(),

            destinationCity: this.x.sub[n].destinationCity.trim(),
            destinationCountry: this.x.sub[n].destinationCountry.trim(),

            destinationLocation: this.x.sub[n].destinationLocation.trim(),
            destinationAddress: this.x.sub[n].destinationAddress.trim(),

            sourcelong: this.x.sub[n].sourcelong
              ? this.x.sub[n].sourcelong.toString()
              : "NA",
            sourcelat: this.x.sub[n].sourcelat
              ? this.x.sub[n].sourcelat.toString()
              : "NA",

            destinationlong: this.x.sub[n].destinationlong
              ? this.x.sub[n].destinationlong.toString()
              : "NA",

            destinationlat: this.x.sub[n].destinationlat
              ? this.x.sub[n].destinationlat.toString()
              : "NA",

            truckType: parseInt(this.x.sub[n].truckType),
            natureOfCargo: this.x.selectedIndustry,
            packingType: this.x.packingType,
            startDate: start_date,
            pickupTime: this.x.sub[n].pickupTime.toString(),
            startDateTime: start_date_time,
            priceType: this.x.sub[n].priceType.toString(),
            quantity: this.x.sub[n].numberOfAssignmentsRequired,
            isFrieghtDocument: this.x.sub[n].jobBooking.toString(),
            price: jobPrice.toString(),
            customerCurrency: this.x.customerCurrency,
            perAssignmentPrice: perAssignmentPrice.toString(),
            jobType: parseInt(this.x.sub[n].jobType),
            jobGeoType: parseInt(this.x.sub[n].jobGeoType),

            jobMode: parseInt(this.x.sub[n].jobMode),
            commodity: commodity || "",
          };
          if (this.x.invoiceBy == 1) {
            (assignment.destinationCityArabic =
              this.x.sub[n].arabicdestinationCity.trim()),
              (assignment.destinationCountryArabic =
                this.x.sub[n].arabicdestinationCountry.trim()),
              (assignment.sourceCityArabic =
                this.x.sub[n].arabicsourceCity.trim()),
              (assignment.sourceCountryArabic =
                this.x.sub[n].arabicsourceCountry.trim());
          }
          if (this.x.sub[n].loadType) {
            assignment.loadType = parseInt(this.x.sub[n].loadType);
          }

          assignments.push(assignment);
        }
        var obj = {
          userId: this.x.customerId,
          customerCurrency: this.x.customerCurrency,
          numberOfAssignmentsRequired: totalAssignmentsRequired,
          jobPrice: totalJobPrice.toString(),
          proof: this.x.proof || [],
          assignment: assignments,
          jobTypeArray: jobTypeArray,
          timeZone: timezone,
          jobGeoType: JobGeoTypeArray,
          jobMode: jobModeArray,
          customerVatNo: this.x.vatNumber || "",
          customerAddress: this.x.customerAddress,
          chargesType: this.x.chargesType,
        };
        if (this.x.weightInTons) {
          obj.weightInTons = this.x.weightInTons.toString();
        }

        if (loadModeArray.length) {
          obj.loadType = loadModeArray;
        }

        if (this.x.otherDetails || this.otherDetails != null) {
          obj.otherDetails = this.x.otherDetails;
        }

        if (this.x.contactPersonName) {
          obj.contactPersonName = this.x.contactPersonName;
        }
        if (this.x.contactAddress) {
          obj.contactAddress = this.x.contactAddress;
        }
        if (this.x.proof) {
          obj.proof = this.x.proof;
        }
        obj.workingCountry = this.workingCountry;

        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let url = createJob;

        this.axios.post(this.constants.apiUrl + url, obj, config).then(
          (response) => {
            this.loading = false;
            if (response.status === 200) {
              this.closeTimeDate = new Date();
              this.identify(localStorage.getItem("email"));
              this.track("Job Created", {
                "Job Id": response.data.data.jobId.toString(),
                "Company Name/Customer Name":
                  response.data.data.companyName || response.data.data.customer,
                "No. of assignmnets":
                  response.data.data.numberOfAssignmentsRequired,
                "Job Start Date": moment
                  .unix(response.data.data.startDate)
                  .format(),
                "Working Country": this.workingCountry,
                Status: this.getStatus(response.data.data.jobStatus),
                "Job Creation Duration": this.getformFillDuration(),
                "Created At": moment
                  .unix(response.data.data.createdAt)
                  .format()
                  .toString(),
              });
              localStorage.setItem("activeMenu", 2);

              this.$router.push("/procurements");
            }
          },
          (error) => {
            this.error = error.response.data.message;
            this.loading = false;
          }
        );
      } else {
        if (!this.x.customerId) {
          // this.error = "Please select the company name before moving ahead!";
          window.scrollTo(0, 0);
          this.loading = false;
          return;
        }

        if (!this.x.packingType) {
          // this.error = "Please select packing type before moving ahead!";
          this.loading = false;
          window.scrollTo(0, 0);
          return;
        }

        if (!this.x.selectedIndustry) {
          window.scrollTo(0, 0);
          this.loading = false;
          return;
        }
        if (!this.x.customerAddress) {
          window.scrollTo(0, 0);
          this.loading = false;
          return;
        }
        if (!this.x.vatNumber) {
          window.scrollTo(0, 0);
          this.loading = false;
          return;
        }
        if (!this.x.invoiceBy) {
          window.scrollTo(0, 0);
          this.loading = false;
          return;
        }
        this.error = "Please fill  all required fields in above form! ";
        if (!this.x.contactAddress) {
          this.loading = false;
          return;
        }
        if (!this.x.contactPersonName) {
          this.loading = false;
          return;
        }

        this.loading = false;
      }
    },

    goBack() {
      this.$router.go(-1);
    },

    fillCustomerName() {
      this.x.customerId = this.$route.params.id;
      this.getCustomer(this.$route.params.id);
    },
    getCustomer(id) {
      this.loading = true;
      let url = custDetails;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        userId: id,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.customerId = response.data.data.userId;

          this.ownerName =
            response.data.data.companyName || response.data.data.email;
        },
        (error) => {
          this.x.error = "Failed to Fetch User Details";
        }
      );
    },
    getCurrentDate() {
      let x = new Date();
      return moment(x).format("YYYY-MM-DD hh:mm a");
    },
    agree(n) {
      this.x.sub[n - 1].jobPrice = 0;
      this.x.sub[n - 1].perAssignmentPrice = 0;
      this.x.sub[n - 1].numberOfAssignmentsRequired = 1;
      this.dialog = false;
    },
    agree1(n) {
      this.deleteStepConfirmed(n);
      this.dialog1 = false;
    },
    cancel2() {
      this.dialog = false;
    },
    cancel1() {
      this.dialog1 = false;
    },
    validationCheck(n) {
      this.error = "";

      if (this.x.sub[n].jobMode == null) {
        this.error = "Please select Job mode before moving ahead!";
        this.loading = false;
        return;
      }

      if (!this.x.sub[n].truckType) {
        this.error =
          "Please select truck's category and sub category before moving ahead!";
        this.loading = false;
        return;
      }
    },
  },
};
</script>

<style>
.v-stepper__header {
  justify-content: left !important;
}
.card-divider {
  border: 0.5px solid #dddddd;
  margin: 15px -33px;
}
.divider-rule {
  border: 0;
  height: 2px;
  margin: 30px 0;
  background-color: transparent;
}
.test1 {
  position: absolute;
  fill: rgba(0, 0, 0, 0.54);
  left: 20px;
  top: 20px;
  margin: 0 !important;
}
.content-border1 {
  border: 2px solid #ffe0bb;
}
.m-t-7 {
  margin-top: 7px !important;
}
.m-t-29 {
  margin-top: 29px !important;
}
.add-btn {
  background-color: #ffffff;
  color: darkorange !important;
  border: 2px solid darkorange;
  margin: 0 20px;
  padding: 5px;
}
div ::-webkit-scrollbar-thumb {
  background: darkorange;
}
div ::-webkit-scrollbar {
  width: 10px;
}
::-webkit-input-placeholder {
  /* Edge */
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  color: rgba(0, 0, 0, 0.54);
}
.test1 {
  position: absolute;
  fill: rgba(0, 0, 0, 0.54);
  left: 20px;
  top: 20px;
  margin: 0 !important;
}
.active {
  background-color: darkorange !important;
}
.minHeight {
  min-height: 300px;
}

.vue__time-picker input.display-time {
  border-radius: 0px !important;
  border-bottom: 1px solid #d2d2d2 !important;
  border: none;
  padding: 0px !important;
}
.pt-20 {
  padding: 18px;
}
.end-style {
  position: relative;
  right: 0px !important;
  left: 998px;
}
.fonttime {
  font-size: 15px;
}
.test2 {
  position: absolute;
  fill: rgba(0, 0, 0, 0.54);
  left: -5px;
  top: 23px;
  margin: 0 !important;
}
</style>
